import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'content-comunicacao';

  get typedTextSpan(){
    return document.querySelector(".typed-text")
  }
  get cursorSpan() {
    return document.querySelector(".cursor")
  }

  textArray = ["o futuro", "modernidade", "criatividade", "a Content Comunicação!"];
  typingDelay = 200;
  erasingDelay = 100;
  newTextDelay = 2000; 
  textArrayIndex = 0;
  charIndex = 0;

  type = () => {
    if (this.charIndex < this.textArray[this.textArrayIndex].length) {
      if (!this.cursorSpan!.classList.contains("typing")) this.cursorSpan!.classList.add("typing");
      this.typedTextSpan!.textContent += this.textArray[this.textArrayIndex].charAt(this.charIndex);
      this.charIndex++;
      setTimeout(this.type, this.typingDelay);
    }
    else {
      this.cursorSpan!.classList.remove("typing");
      setTimeout(this.erase, this.newTextDelay);
    }
  }


  erase = () => {
    if (this.charIndex > 0) {
      if (!this.cursorSpan!.classList.contains("typing")) this.cursorSpan!.classList.add("typing");
      this.typedTextSpan!.textContent = this.textArray[this.textArrayIndex].substring(0, this.charIndex - 1);
      this.charIndex--;
      setTimeout(this.erase, this.erasingDelay);
    }
    else {
      this.cursorSpan!.classList.remove("typing");
      this.textArrayIndex++;
      if (this.textArrayIndex >= this.textArray.length) this.textArrayIndex = 0;
      setTimeout(this.type, this.typingDelay + 1100);
    }
  }

  ngOnInit(): void {
    setTimeout(this.type, this.newTextDelay + 250);
  }
}
