<header id="header">
  <h1><a href="index.html"><img src="assets/logo.png" class="logo" height="80px"></a></h1>
  <nav id="nav">
    <ul>
      <li><a href="#banner">Início</a></li>
      <li><a href="#about">Quem somos</a></li>
      <li><a href="#services">Serviços</a></li>
      <li><a href="#team">Equipe</a></li>
      <li><a href="#footer">Contato</a></li>
    </ul>
  </nav>
</header>

<section id="banner">
  <ul class="circles">
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
  </ul>

  <h2>Nós somos 
    <span class="typed-text"></span>
    <span class="cursor">&nbsp;</span>
  </h2>

  <div class="container z-index-fix">
    <p>Vamos te ajudar a criar conteúdo de autoridade nas redes sociais e melhorar a sua imagem no mercado!</p>
    <ul class="actions">
      <li>
        <a href="#about" class="button big">Saiba mais</a>
      </li>
    </ul>
  </div>
</section>

<section id="about" class="wrapper anchor-trick">
  <div class="container">
    <header class="major">
      <h2>Quem somos</h2>
      <p style="width: 100%; margin: 0; padding: 0; font-size: 15px;">&nbsp;
      </p>
    </header>
    <section class="row 200%">
      <div class="6u 12u$(small)">
        <p class="about-text">
          A <strong><span class="text-primary">Content</span></strong> é uma empresa que tem como foco fortalecer a imagem de seus clientes através dos serviços de comunicação estratégica. Acreditamos que essa é uma ferramenta importante para impulsionar as vendas, reforçar a credibilidade da marca no mercado e melhorar a motivação dos funcionários e clientes.
        </p>
      </div>
      <div class="6u 12u$(small)">
        <img src="assets/img-about-t3.jpg" class="img-responsive" alt="">
      </div>
    </section>


  </div>
</section>

<section id="services" class="wrapper style1 align-center anchor-trick">
  <div class="container">
    <header class="major">
      <h2>Nossos Serviços</h2>
    </header>
    <div class="row 200%">

      <section class="4u 12u$(small)" data-aos="fade-up" data-aos-duration="500">
        <object data="assets/svg/noun_LLight_3237958.svg" class="icon-svg" type="image/svg+xml">
        </object>  
        <!-- <i class="icon big rounded fa-user"></i> -->

        <h2>Consultoria</h2>
        <p>
          A consultoria Content tem como objetivo apresentar ao cliente cursos e treinamentos para criação de  conteúdo, estratégias, posicionamento e gerenciamento da marca no universo digital.
        </p>
      </section>

      <section class="4u 12u$(small) module" data-aos="fade-up" data-aos-delay="100" data-aos-duration="500">
        <object data="assets/svg/noun_Social Media_3371282.svg" class="icon-svg" type="image/svg+xml">
        </object>  
        <h2>Gerenciamento de redes sociais</h2>
        <p>
          Definição de persona, postagens semanais Instagram (story e feed) e facebook (story e feed), criação de FAQ com marketing humanizado.
        </p>
      </section>
      <section class="4u$ 12u$(small) module" data-aos="fade-up" data-aos-delay="200" data-aos-duration="500">
        <object data="assets/svg/noun_sheet_3526154.svg" class="icon-svg" type="image/svg+xml">
        </object>  
        <h2>Planejamento de mídia</h2>
        <p>
          Reuniões mensais com o cliente, criação de conteúdo semanal, publicação mediante a aprovação do cliente e concepção e criação de hashtags.
        </p>
      </section>
    </div>
    <div class="row 200%">
      <section class="4u 12u$(small)" data-aos="fade-up" data-aos-duration="500">
        <object data="assets/svg/noun_Communication_2405511.svg" class="icon-svg" type="image/svg+xml">
        </object>        
        <h2>Criação de conteúdo</h2>
        <p>
          Produção de posts, avatares, capas, textos e artes das publicações. Fotos e vídeos simples.
        </p>
      </section>
      <section class="4u 12u$(small)" data-aos="fade-up" data-aos-delay="100" data-aos-duration="500">
        <object data="assets/svg/noun_Megaphone_870422.svg" class="icon-svg" type="image/svg+xml">
        </object>  
        <h2>Assessoria de imprensa</h2>
        <p>
          Divulgação em veículos de comunicação tradicionais como jornais, tv's, rádios e canais digitais.
        </p>
      </section>
      <section class="4u 12u$(small)" data-aos="fade-up" data-aos-delay="200" data-aos-duration="500">
        <object data="assets/svg/noun_Microphone_3695723.svg" class="icon-svg microphone" type="image/svg+xml">
        </object>  
        <h2>Media training</h2>
        <p>
          O media training tem como objetivo preparar os líderes das empresas para se tornarem porta-vozes para os veículos de comunicação em geral.
        </p>
      </section>
    </div>
  </div>
</section>

<section id="team" class="wrapper align-center anchor-trick">
  <div class="container">
    <header class="major">
      <h2>Equipe</h2>
    </header>
    <div class="row 200%">
      <section class="1u 12u$(small)">
        &nbsp;
      </section>
      <section class="4u 12u$(small)" data-aos="fade-up" data-aos-duration="500">
        <img src="assets/marilia-square.jpeg"  class="profile img-responsive" />
        <h2>Marília Simas</h2>
        <p>
          Com dez anos de experiência entre redações de jornais e agências de comunicação, Marília Simas é jornalista formada pela Unicap e pós graduada em Direitos Humanos.
        </p>
      </section>
      <section class="2u 12u$(small)">
        &nbsp;
      </section>
      <section class="4u$ 12u$(small)" data-aos="fade-up" data-aos-delay="100" data-aos-duration="500">
        <img src="assets/cinthia-square.jpeg" class="profile img-responsive" />
        <h2>Cinthia Ferreira</h2>
        <p>
          Formada em Jornalismo pela Unicap, Cinthia Ferreira atua há dez anos no mercado da comunicação. Tem experiência em assessoria de imprensa e hoje é repórter na Tv e Rádio Jornal.
        </p>
      </section>
      <section class="1u 12u$(small)">
        &nbsp;

      </section>
    </div>
  </div>
</section>

<footer id="footer">
  <div class="container">
    <div class="row">
      <section class="4u 6u(medium) 12u$(small)">
        <h3>Contate-nos</h3>
        <p><a href="mailto:contentcomunicacao@gmail.com">contentcomunicacao@gmail.com</a></p>
      </section>
      <!-- <section class="4u 6u$(medium) 12u$(small)">
        <h3>Nostrum, repellat!</h3>
        <p>Tenetur voluptate exercitationem eius tempora! Obcaecati suscipit, soluta earum blanditiis.</p>
      </section> -->
      <section class="4u$ 12u$(medium) 12u$(small)">
        <h3>Sigam-me os bons</h3>
        <ul class="icons">
          <li><a href="https://www.instagram.com/content.comunicacao/" target="_blank" class="icon rounded fa-instagram"><span class="label">Instagram</span></a></li>
          <li><a href="#" class="icon rounded fa-twitter"><span class="label">Twitter</span></a></li>
          <li><a href="#" class="icon rounded fa-facebook"><span class="label">Facebook</span></a></li>
          <!-- <li><a href="#" class="icon rounded fa-pinterest"><span class="label">Pinterest</span></a></li> -->
          <!-- <li><a href="#" class="icon rounded fa-google-plus"><span class="label">Google+</span></a></li> -->
          <li><a href="#" class="icon rounded fa-linkedin"><span class="label">LinkedIn</span></a></li>
        </ul>
      </section>
    </div>
    <!-- <ul class="copyright">
      <li>&copy; Untitled. All rights reserved.</li>
      <li>Design: <a href="http://templated.co">TEMPLATED</a></li>
      <li>Images: <a href="http://unsplash.com">Unsplash</a></li>
    </ul> -->
  </div>
</footer>